<template>
  <div class="login mt-6 pt-6">
    <div class="container">
      <div class="columns is-justify-content-center">
        <div class="column is-two-fifths-tablet">
          <header class="has-text-centered">
            <img
                class="login-logo mb-4"
                src="/img/assets/logo.png"
                alt="Sgara Logo">

            <h3 class="is-size-4 has-text-weight-bold">Login to Sgara</h3>
          </header>

          <b-notification
              :active.sync="showError"
              :closable="false"
              class="my-4 has-text-centered"
              type="is-danger is-light"
              aria-close-label="Close notification"
              role="alert">
            {{ errorMessage }}
          </b-notification>

          <ValidationObserver ref="form" v-if="!loginSuccess">
            <form @submit.prevent="handleSubmit" class="mt-4">

              <p class="has-text-centered mb-4">{{ $t('user.login_using') }}</p>

              <ValidationProvider rules="" vid="loginBy">
                <b-field>
                  <b-radio-button v-model="loginBy"
                                  native-value="phone"
                                  expanded
                                  type="is-primary">
                    <b-icon icon="phone"></b-icon>
                    <span>{{ $t('user.phone') }}</span>
                  </b-radio-button>
                  <b-radio-button v-model="loginBy"
                                  native-value="email"
                                  expanded
                                  type="is-primary">
                    <b-icon icon="email"></b-icon>
                    <span>Email</span>
                  </b-radio-button>
                </b-field>
              </ValidationProvider>

              <div v-if="loginBy === 'phone'">
                <ValidationProvider :name="$t('user.phone')" rules="required_if:loginBy,phone" v-slot="{ errors }">
                  <b-field :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <vue-phone-number-input
                        color="#FF6600"
                        valid-color="#45BE7D"
                        error-color="#FF4060"
                        default-country-code="ID"
                        v-model="phone" @update="handleInputPhoneNumber" :placeholder="$t('user.phone')"/>
                  </b-field>
                </ValidationProvider>
              </div>
              <div v-else-if="loginBy === 'email'">
                <ValidationProvider name="Email" rules="required_if:loginBy,email|email" v-slot="{ errors }">
                  <b-field :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input type="email" id="email" v-model="user.email" placeholder="Enter your Email"></b-input>
                  </b-field>
                </ValidationProvider>
                <ValidationProvider name="Password" rules="required_if:loginBy,email" v-slot="{ errors }" vid="password">
                  <b-field :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input type="password" id="password" v-model="user.password"
                             placeholder="Enter your Password"></b-input>
                  </b-field>
                </ValidationProvider>
              </div>

              <div class="has-text-right mb-2" v-if="loginBy === 'email'">
                <router-link to="/forgot-password">Forgot Password?</router-link>
              </div>

              <b-button type="is-primary is-gradient" expanded class="mb-3" native-type="submit">Login</b-button>
            </form>
          </ValidationObserver>
          <div v-else>
            <b-notification
                :closable="false"
                class="my-4 has-text-centered"
                type="is-primary is-light"
                aria-close-label="Close notification"
                role="alert">
              OTP has been sent to your Whatsapp Number
            </b-notification>

            <b-field class="my-5">
              <otp-input
                  class="is-justify-content-center"
                  ref="otpInput"
                  input-classes="otp-input"
                  separator=""
                  :num-inputs="4"
                  :should-auto-focus="true"
                  :is-input-num="true"
                  @on-complete="handleOnCompleteOtp"
              />
            </b-field>

            <b-button type="is-primary" expanded outlined @click="resendOtp" :disabled="resendOtpTimer > 0">Resend OTP
              <span class="ml-1" v-if="resendOtpTimer > 0">({{ resendOtpTimer }})</span></b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OtpInput from "@bachdgvn/vue-otp-input";
import {mapActions} from "vuex";

export default {
  name: "Login",
  components: {
    OtpInput,
  },
  data: () => {
    return {
      loginBy: 'phone',
      user: {},
      showError: false,
      errorMessage: '',
      phone: null,
      loginSuccess: false,
      lastSendOtp: null,
      resendOtpTimer: 0,
      otpInterval: null,
    }
  },
  methods: {
    ...mapActions('user', [
      'loginEmail',
      'loginPhone',
      'loginOtp',
    ]),

    handleInputPhoneNumber(payload) {
      this.user.phone = payload.e164;
    },

    async handleSubmit() {
      this.$refs.form.validate().then(async success => {
        if (!success) return 0

        this.showError = false
        this.errorMessage = ''

        this.$loading()
        if (this.loginBy === 'phone') {
          let res = await this.doLogin()
          if (!res.error && res.data.success) {
            this.loginSuccess = true
          } else {
            if (res.data.message) {
              this.showError = true
              this.errorMessage = res.data.message
            }
          }

          this.$nextTick(() => {
            this.$loading(false)
          })
        } else {
          let res = await this.loginEmail(this.user)
          this.$loading(false)
          if (!res.error) return this.$router.push('/farm')
          else {
            if (res.data.message) {
              this.showError = true
              this.errorMessage = res.data.message
            }
          }
        }
      })
    },

    async doLogin() {
      return await this.loginPhone(this.user)
    },

    async resendOtp() {
      this.$loading()
      await this.handleOtpTimer()
      await this.doLogin()
      this.$loading(false)
    },

    handleOtpTimer() {
      if (this.otpInterval) clearInterval(this.otpInterval)
      this.lastSendOtp = this.$moment().add({minutes: 1})
      this.otpInterval = setInterval(() => {
        this.resendOtpTimer = this.lastSendOtp.diff(this.$moment(), 'seconds')
        if (this.resendOtpTimer === 0) clearInterval(this.otpInterval)
      }, 1000)
    },

    async handleOnCompleteOtp(value) {
      this.$loading()

      this.showError = false
      this.errorMessage = ''

      this.$loading()
      let res = await this.loginOtp({
        phone: this.user.phone,
        otp: value,
      })

      if (!res.error && res.data.token) {
        this.$router.push('/farm')
      } else {
        if (res.data.message) {
          this.showError = true
          this.errorMessage = res.data.message
        }
      }

      this.$loading(false)
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .field.has-addons .control.is-expanded {
    flex: 1;
  }
}
</style>
